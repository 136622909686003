import { AxiosInstance, AxiosResponse } from 'axios';

import { AxiosRequest } from '@asa/asasqt-microfrontend-components/dist/common/api/axios/utils/types';
import {
  Address,
  AddressListType,
  GenderListType,
} from '@asa/asasqt-microfrontend-components/dist/common/types/customer';
import { SortOption } from '@asa/asasqt-microfrontend-components/dist/layout/ui/GridList/types';

export interface CustomerVehicle {
  vin: string;
  licensePlate: string;
  name: string;
  lastInspection: string;
  nextInspection: string;
  serviceDate: string;
  mileage: number;
  customerId: number;
  registrationDate: string;
  warrantyStartDate: string;
  warrantyEndDate: string;
  printOption: string;
  brand: string;
  market: string;
  catalogNumber: string;
}

export interface CustomerVehicleResponse extends AxiosRequest {
  customerId: string;
}

export interface GetVehiclesRequest extends AxiosRequest {
  suffix: string;
  accountLogin: string;
}

export interface ExternalIdVehicleType extends AxiosRequest {
  vehicleExternalId: string;
}

export interface UpdateCustomerRequest extends AxiosRequest {
  customerId: number;
  data: Customer;
}

export interface UpdateVehicleRequest extends AxiosRequest {
  customerId: number;
  vehicleId: number;
  data: CustomerVehicle;
}

export interface UpdateCustomerData {
  customerNumber: number;
  customerReference: string;
  customerName: string;
}

export interface VehicleByExternalIdResponse extends AxiosResponse {
  data: VehicleByExternalId;
}

export interface VehicleByExternalId {
  id: number;
  externalId: string;
  vin: string;
  vehicle: string;
  licensePlate: string;
  name: string;
  lastInspection: string;
  nextInspection: string;
  serviceDate: string;
  mileage: number;
  registrationDate: string;
  warrantyStartDate: string;
  warrantyEndDate: string;
  printOption: string;
  brand: string;
  market: string;
  catalogNumber: string;
  customer: Customer;
}

export interface GetAllVehiclesRequest extends AxiosRequest {
  page: number;
  sortOption?: SortOption;
  appointmentDateFilters: AppointmentDateFilterData;
}

export interface GetVINSearchHistoryRequest extends AxiosRequest {
  limit: number;
}
export interface GetDmsVehiclesRequest extends AxiosRequest {
  accountLogin: string;
  dealerNumber: string;
  market?: string;
  catalog?: string;
  dmsParams?: DmsSearchByParamsWithDefault;
  requestToken?: string;
}

export interface DmsVehiclesResponse extends AxiosRequest {
  data: DmsVehicle[];
}

export interface DmsVehicle {
  id: number;
  orderId: string;
  vin: string;
  licensePlate: string;
  market: string;
  catalog: string;
  model: string;
  customerName: string;
  customerNumber: number;
  productionDate: string;
  productionFrom: string;
  productionUntil: string;
  phone: string;
  email: string;
  serviceDate: string;
  serviceTime: string;
  billingAddress: string;
  deliveryAddress: string;
  isExternal: boolean;
  externalId?: string;
  customerReference: string;
  orderType: string;
  customerContract: string;
  shippingType: string;
  voucher: string;
  name: string;
}

export interface CreateVehicleForCustomerRequest extends AxiosRequest {
  customerId: number;
  data: CreateVehicleData;
}

export interface AddAddressesType {
  axiosIntercept: AxiosInstance;
  address: Address;
  customerId: number;
  sameBillingDelivery: boolean;
}

export interface EditAddressesType {
  axiosIntercept: AxiosInstance;
  address: Address;
  customerId: number;
  addressId: number;
}

export interface CreateCustomerRequest extends AxiosRequest {
  data?: CreateCustomerData;
}

export interface CreateAppointmentFilterRequest extends AxiosRequest {
  data: AppointmentFilterData;
}

export interface CreateAppointmentFilterResponse extends AxiosResponse {
  data: AppointmentFilterData;
}

export interface AppointmentFilterData {
  allFilters?: boolean;
  today?: boolean;
  thisWeek?: boolean;
  custom?: boolean;
  serviceDateFrom?: string;
  serviceDateUntil?: string;
  serviceTimeFrom?: string;
  serviceTimeUntil?: string;
}

export interface CreateCustomerData {
  customerNumber?: number;
  customerReference?: string;
  customerName?: string;
  gender?: GenderListType;
  addresses?: Address[];
}
export interface DashboardVehiclesResults {
  count: number;
  items: DashboardVehicle[];
}
export interface DashboardVehicle {
  productionUntil?: string;
  productionFrom?: string;
  name: string;
  idx: number;
  externalId: string;
  vin: string;
  catalogNumber: string;
  ops: string;
  catalogProdFrom: string;
  catalogProdTo: string;
  exteriorCode: string;
  interiorCode: string;
  productionDate: string;
  market: string;
  licensePlate: string;
  customerName: string;
  phone: string;
  email: string;
  serviceDate?: string;
  serviceTime?: string;
}

export interface CreateVehicleData {
  vin: string;
  catalogNumber: string;
  market: string;
  licensePlate: string;
  serviceDate?: string;
  brand?: string;
}

export interface Customer {
  companyIdentifier?: number;
  id: number;
  externalId: string;
  customerNumber: number;
  customerName: string;
  contactMethod: string;
  contactMethodOther: string;
  paymentMethod: string;
  paymentMethodOther: string;
  gender: string;
  title: string;
  company: string;
  department: string;
  position: string;
  email: string;
  homePhone: string;
  mobilePhone: string;
  workPhone: string;
  fax: string;
  addresses: CustomerAddress[];
}

export interface CustomerAddress {
  id: number;
  addressType: AddressListType;
  addressFirst: string;
  addressSecond: string;
  poBox: string;
  postalCode: string;
  city: string;
  provinceState: string;
  country: string;
}

export interface CustomerResponse extends AxiosRequest {
  data: Customer;
}

export interface NewCustomerVehicle {
  id: number;
  externalId: string;
  vin: string;
  model: string;
  licensePlate: string;
  name: string;
  lastInspection: string;
  nextInspection: string;
  serviceDate: string;
  mileage: number;
  customerId: number;
  vinDistributorMemo: string;
  vinCpsInfo: string;
  modelYear: string;
  engineCode: string;
  transmission: string;
  transmissionCode: string;
  drive: string;
  interior: string;
  exterior: string;
  option: string;
  registrationDate: string;
  warrantyStartDate: string;
  warrantyEndDate: string;
  printOption: string;
  brand: string;
  market: string;
  catalogNumber: string;
}

export interface CustomerVehicleResponse extends AxiosRequest {
  data: NewCustomerVehicle;
}

export enum DmsSearchByValues {
  CustomerName = 'CUSTOMER_NAME',
  Vin = 'VIN',
  LicensePlate = 'LICENSE_PLATE',
  CustomerNumber = 'CUSTOMER_NUMBER',
  OrderId = 'DMS_ORDER_ID',
}

export enum AppointmentDatePeriod {
  All = 'ALL',
  Today = 'TODAY',
  ThisWeek = 'THIS_WEEK',
  Custom = 'CUSTOM',
}

export interface AppointmentDateFilterData {
  period?: AppointmentDatePeriod;
  from?: string | null;
  until?: string | null;
  dates?: [Date, Date];
}
export type DmsSearchByParams = {
  [key in DmsSearchByValues]?: string;
};

export type DmsSearchByParamsWithDefault = DmsSearchByParams & {
  defaultSelectedOnDropdown?: DmsSearchByValues;
};
