export {
  isAuthenticatedAtom,
  setRememberMeFlagAtom,
  credentialsAtom,
  tokenAtom,
} from '../../auth/core/auth.recoil';
export {
  hasLegalDocsToAcceptAtom,
  legalDocumentsAtom,
  legalDocumentsAcceptanceAtom,
} from './legalDocuments.recoil.atom';
export * from './types';
