import React, { useContext } from 'react';
import { useRecoilState } from 'recoil';

import {
  Box,
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';

import { UserIcon } from '@asa/asasqt-microfrontend-components/dist/Icons/User';
import { GoChevronRight } from '@asa/asasqt-microfrontend-components/dist/Icons/lib/GoChevronRight';
import { RiArrowDownSFill } from '@asa/asasqt-microfrontend-components/dist/Icons/lib/RiArrowDownSFill';
import { userDetailsAtom } from '@asa/asasqt-microfrontend-components/dist/common/state/shared/auth';
import { Icon } from '@asa/asasqt-microfrontend-components/dist/typography/Icons';
import { Text } from '@asa/asasqt-microfrontend-components/dist/typography/Text';

import { LanguageContext } from '@/core/common/language';
import {
  RedirectLandingPages,
  myAsaInfoContactPage,
  myAsaInfoDownloadsPage,
  myAsaInfoLandingPage,
} from '@/core/settings/utils';

import useLogout from '../../hooks/useLogout';
import { legalDocumentsAtom } from '../../recoil';
import { Documents, LegalDocType } from '../../types';
import { MenuLink } from './menuLink';

export const AccountMenu = ({ ...rest }) => {
  const { tr } = useContext(LanguageContext);
  const { initiateLogout } = useLogout();

  const [userDetails] = useRecoilState(userDetailsAtom);
  const [legalDocs] = useRecoilState<Documents>(legalDocumentsAtom);

  return (
    <Menu placement="bottom-end" {...rest}>
      <Divider orientation="vertical" />
      <MenuButton color={'gray.900'} borderRadius="full">
        <Box
          display={'inline-flex'}
          alignContent={'center'}
          pl={4}
          position={'relative'}
          top={2}
        >
          <Icon icon={UserIcon} fontSize={24} right={'2px'} />
          <Text ml={2}>
            {userDetails && (
              <>
                {userDetails.accountLogin}\{userDetails.userLogin}
              </>
            )}
          </Text>
          <Icon
            fontSize={16}
            icon={RiArrowDownSFill}
            position={'relative'}
            top={1}
          />
        </Box>
      </MenuButton>
      <MenuList
        maxW="12rem"
        overflow="hidden"
        color="gray.800"
        _dark={{ color: 'white' }}
      >
        <MenuLink
          redirectPage={RedirectLandingPages.MyAsaInfoPortal}
          landingPage={myAsaInfoLandingPage}
          translation={'common.label.myASAInfo'}
        />
        <Menu>
          <MenuButton
            as={Button}
            pt={1.5}
            pb={1.5}
            paddingInline={3}
            background={'white'}
            borderRadius={0}
            fontWeight={'normal'}
            _hover={{ bg: 'gray.100' }}
            w={'100%'}
            textAlign={'start'}
            rightIcon={<GoChevronRight />}
          >
            {tr('common.label.legal.information')}
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() =>
                window.open(legalDocs[LegalDocType.AsaPrivacy]?.url, '_blank')
              }
            >
              {tr('common.label.legal.privacy')}
            </MenuItem>
            <MenuItem
              onClick={() =>
                window.open(
                  legalDocs[LegalDocType.AsaLegalNotice]?.url,
                  '_blank'
                )
              }
            >
              {tr('common.label.impressum')}
            </MenuItem>
            <MenuItem
              onClick={() =>
                window.open(
                  legalDocs[LegalDocType.AsaSqtDataProcessing]?.url,
                  '_blank'
                )
              }
            >
              {tr('common.label.legal.dataProcessing.title')}
            </MenuItem>
            <MenuItem
              onClick={() =>
                window.open(legalDocs[LegalDocType.AsaTos]?.url, '_blank')
              }
            >
              {tr('common.label.legal.tos.title')}
            </MenuItem>
          </MenuList>
        </Menu>
        <MenuLink
          redirectPage={RedirectLandingPages.MyAsaInfoPortal}
          landingPage={myAsaInfoContactPage}
          translation={'common.label.feedback'}
        />
        <MenuLink
          redirectPage={RedirectLandingPages.MyAsaInfoPortal}
          landingPage={myAsaInfoDownloadsPage}
          translation={'common.label.help'}
        />

        <MenuDivider />
        <MenuItem onClick={() => initiateLogout()}>
          {tr('common.label.action.logout')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
