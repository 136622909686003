import React, { useContext } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { Flex, Link, Stack } from '@chakra-ui/react';

import { ManageAccountIcon } from '@asa/asasqt-microfrontend-components/dist/Icons/ManageAccount';
import { userDetailsAtom } from '@asa/asasqt-microfrontend-components/dist/common/state/shared/auth';
import { Icon } from '@asa/asasqt-microfrontend-components/dist/typography/Icons';
import { Text } from '@asa/asasqt-microfrontend-components/dist/typography/Text';

import { RememberMeAtom } from '@/core/auth/core/auth.recoil';
import { LanguageContext } from '@/core/common/language';

import useLogout from '../../hooks/useLogout';
import { setRememberMeFlagAtom } from '../../recoil';

export const AccountMenuMobile = ({ ...rest }) => {
  const { tr } = useContext(LanguageContext);
  const { initiateLogout } = useLogout();

  const [rememberMe] = useRecoilState(RememberMeAtom);
  const [userDetails] = useRecoilState(userDetailsAtom);
  const resetRememberMeFlag = useResetRecoilState(setRememberMeFlagAtom);
  const resetRememberMe = useResetRecoilState(RememberMeAtom);

  const triggerLogout = () => {
    initiateLogout();

    if (rememberMe.accepted) {
      resetRememberMeFlag();
      resetRememberMe();
    }
  };

  return (
    <Stack
      direction={'column'}
      alignContent={'center'}
      justifyContent={'center'}
      marginBottom={'auto'}
      p={6}
      mt={2}
      {...rest}
    >
      <Flex>
        <Icon icon={ManageAccountIcon} iconProps={{ h: 6, w: 6 }} />
        <Text ml={2}>
          {userDetails && (
            <>
              {userDetails.accountLogin}\{userDetails.userLogin}
            </>
          )}
        </Text>
      </Flex>
      <Link onClick={triggerLogout}>{tr('common.label.action.logout')}</Link>
    </Stack>
  );
};
