import { atom } from 'recoil';

import {
  persistAtomOnLocalStorage,
  persistAtomOnSessionStorage,
} from '@asa/asasqt-microfrontend-components/dist/common/state/recoilPersist';

import { RememberMeType } from '../utils/auth.type';

export const isAuthenticatedAtom = atom({
  key: 'isAuthenticated',
  default: false,
  effects_UNSTABLE: [persistAtomOnSessionStorage],
});

export const setRememberMeFlagAtom = atom<boolean>({
  key: 'setRememberMeFlag',
  default: false,
  effects_UNSTABLE: [persistAtomOnSessionStorage],
});

export const credentialsAtom = atom({
  key: 'credentials',
  default: undefined,
});

export const tokenAtom = atom({
  key: 'token',
  default: '',

  effects_UNSTABLE: [persistAtomOnSessionStorage],
});

export const hasLicenseAtom = atom({
  key: 'hasLicense',
  default: undefined as unknown as boolean,
});

export const loginButtonIsLoadingAtom = atom({
  key: 'buttonIsLoading',
  default: false,
});

const initialState: RememberMeType = { accepted: false };

export const RememberMeAtom = atom({
  key: 'rememberMe',
  default: initialState,
  effects_UNSTABLE: [persistAtomOnLocalStorage],
});
