import { useEffect } from 'react';

interface SiteIds {
  dev: number;
  qs: number;
  demo: number;
  prod: number;
}

const useMatomo = () => {
  const getMatomoSiteId = (hostname: string) => {
    const siteIds: SiteIds = {
      dev: 16,
      qs: 18,
      demo: 20,
      prod: 6,
    };

    if (hostname.includes('dev')) {
      return siteIds.dev;
    } else if (hostname.includes('qs')) {
      return siteIds.qs;
    } else if (hostname.includes('demo')) {
      return siteIds.demo;
    } else if (hostname.includes('www')) {
      return siteIds.prod;
    }
  };

  useEffect(() => {
    window._paq = window._paq !== null ? window._paq : [];
    if (window) {
      const hostname = window.location.hostname;
      if (
        hostname === 'localhost' ||
        hostname.startsWith('127.') ||
        hostname === '[::1]'
      ) {
        //Enable Matomo only on prod enviroments
        return;
      }
      if (!window._paq) {
        // Check if Matomo script is not already initialized
        window._paq = window._paq || [];
        window._paq.push(['setExcludedQueryParams', ['vin', 'q']]);
        window._paq.push(['trackPageView']);
        window._paq.push(['enableLinkTracking']);
        (() => {
          const u = 'https://asametrics.lex-com.net/';
          window._paq.push(['setTrackerUrl', `${u}matomo.php`]);
          window._paq.push(['setSiteId', getMatomoSiteId(hostname)]);
          const d = document,
            g = d.createElement('script'),
            s = d.getElementsByTagName('script')[0];
          g.type = 'text/javascript';
          g.async = true;
          g.src = `${u}matomo.js`;
          s.parentNode?.insertBefore(g, s);
        })();
      }
    }
  }, []);

  return null;
};

export default useMatomo;
