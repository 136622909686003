import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { MouseEventHandler } from 'react';
import { UrlObject } from 'url';

import {
  Box,
  Divider,
  Flex,
  Stack,
  StackProps,
  Tooltip,
} from '@chakra-ui/react';

import { CarIcon } from '@asa/asasqt-microfrontend-components/dist/Icons/Car';
import { HomeIcon } from '@asa/asasqt-microfrontend-components/dist/Icons/Home';
import { ManageAccountIcon } from '@asa/asasqt-microfrontend-components/dist/Icons/ManageAccount';
import { QuotationIcon } from '@asa/asasqt-microfrontend-components/dist/Icons/Quotation';
import { SettingsIcon } from '@asa/asasqt-microfrontend-components/dist/Icons/Settings';
import { useLayoutContext } from '@asa/asasqt-microfrontend-components/dist/layout/ui/View';
import { Icon } from '@asa/asasqt-microfrontend-components/dist/typography/Icons';
import { Text } from '@asa/asasqt-microfrontend-components/dist/typography/Text';

import {
  URLRoutes,
  useMappedURLRoute,
  useURLRoutesLang,
} from '@/core/common/helpers';

export type MenuProps = StackProps & {
  isDrawer?: boolean;
};

export const MainMenu = ({ isDrawer, ...rest }: MenuProps) => {
  const router = useRouter();
  const { navOnClose } = useLayoutContext();
  const T = useURLRoutesLang();
  const URLRoutesT = useMappedURLRoute();

  const pathSplit = router.asPath.split('/');
  const activePage = pathSplit[1];
  const isMenuSelection =
    activePage === URLRoutes.menuList &&
    pathSplit.length > 3 &&
    pathSplit[3] === 'details';

  const MenuItem = ({
    onClick,
    href,
    isActive,
    label,
    icon,
    disabled,
    testId,
  }: {
    onClick?: MouseEventHandler | undefined;
    href: string | UrlObject;
    isActive?: boolean;
    label: string;
    icon: TODO;
    disabled?: boolean;
    testId: string;
  }) => {
    const iconProps = { h: 6, w: 6 };
    const activeIconProps = { ...iconProps, color: 'brand.600' };
    const drawerIconProps = { ...iconProps, color: 'white' };

    return (
      <Link
        href={href}
        onClick={onClick}
        style={disabled ? { pointerEvents: 'none' } : {}}
        passHref
        data-testid={testId}
      >
        {!isDrawer ? (
          <Box width={16} justifyContent="center" display="flex">
            <Tooltip label={label}>
              <Icon
                icon={icon}
                fontSize={24}
                right={'6px'}
                color={isActive ? 'brand.600' : 'gray.999'}
                top={'8px'}
                position={'relative'}
                iconProps={isActive ? activeIconProps : iconProps}
              />
            </Tooltip>
            {isActive && (
              <Divider
                backgroundColor="brand.600"
                opacity={1}
                height={0.5}
                width={16}
                borderBottomWidth={0}
                bottom={'calc(0.125rem * -0.1)'}
                position="absolute"
              />
            )}
          </Box>
        ) : (
          <Flex onClick={navOnClose}>
            <Icon icon={icon} iconProps={drawerIconProps} />
            <Text ml={3}>{label}</Text>
          </Flex>
        )}
      </Link>
    );
  };

  return (
    <Stack
      direction={isDrawer ? 'column' : 'row'}
      alignContent={'center'}
      flexGrow={isDrawer ? '1' : 'inherit'}
      justifyContent={!isDrawer ? 'center' : 'inherit'}
      marginTop={isDrawer ? 'auto' : 'inherit'}
      marginLeft={!isDrawer ? 'auto' : 'inherit'}
      {...rest}
    >
      <MenuItem
        label={URLRoutesT[URLRoutes.dashboard]}
        href={`/${URLRoutes.dashboard}`}
        icon={HomeIcon}
        isActive={activePage === URLRoutes.dashboard || isMenuSelection}
        testId={'mainMenu_link_home'}
      />
      <MenuItem
        label={T.VHC}
        href={`/${URLRoutes.vhcList}`}
        icon={CarIcon}
        isActive={
          activePage === URLRoutes.vhcList ||
          activePage === URLRoutes.vehicleHealthCheck
        }
        testId={'mainMenu_link_vhc'}
      />
      <MenuItem
        label={T.quotation}
        href={`/${URLRoutes.menuList}`}
        icon={QuotationIcon}
        isActive={activePage === URLRoutes.menuList && !isMenuSelection}
        testId={'mainMenu_link_quotation'}
      />
      <MenuItem
        label={T.customerVehicles}
        href={`/${URLRoutes.customerList}`}
        icon={ManageAccountIcon}
        isActive={activePage === URLRoutes.customerList}
        testId={'mainMenu_link_customer'}
      />
      <MenuItem
        label={URLRoutesT[URLRoutes.settings]}
        href={`/${URLRoutes.settings}`}
        icon={SettingsIcon}
        isActive={activePage === URLRoutes.settings}
        testId={'mainMenu_link_settings'}
      />
    </Stack>
  );
};
